import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import Avatar from '../components/Avatar'
import { IoMdCall } from "react-icons/io";
import { FaAngleLeft } from "react-icons/fa6";
import Loading from '../components/Loading';
import backgroundImage from '../assets/wallapaper.jpeg'
import { IoMdSend } from "react-icons/io";
import ringtone from '../assets/ringtone.mp3';
import { setSocketConnection, setOnlineUser } from '../redux/userSlice';
import io from 'socket.io-client'
import axios from 'axios'
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Import your notification sound
import notificationSound from '../assets/notification.mp3';
import { IoMdAdd } from "react-icons/io";
import UpdateGroupModal from '../components/UpdateGroupModal';
import moment from 'moment'


const Group = () => {
  const dispatch = useDispatch()
  const location = useLocation(); // Get the current location (route)
  const [isGroupOpen, setIsGroupOpen] = useState(false); // State to track if '/group
  const params = useParams()
  const socketConnection = useSelector(state => state?.user?.socketConnection)
  const user = useSelector(state => state?.user)
  const [dataUser, setDataUser] = useState({
    name: "",
    email: "",
    profile_pic: "",
    online: false,
    _id: ""
  })
  const [openImageVideoUpload, setOpenImageVideoUpload] = useState(false)
  const [message, setMessage] = useState({
    text: "",
    imageUrl: "",
    videoUrl: ""
  })
  const [loading, setLoading] = useState(false)
  const [allMessage, setAllMessage] = useState([])
  // const [showCallModal, setShowCallModal] = useState(false);
  const [showIncomingCallModal, setShowIncomingCallModal] = useState(false);
  const [callerInfo, setCallerInfo] = useState(null);
  const currentMessage = useRef(null)
  const [callTimeout, setCallTimeout] = useState(null);  // State to manage call timeout
  const ringtoneRef = useRef(new Audio(ringtone));
  const [groupData, setGroupData] = useState([]);
  const [senderId, setSenderId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groupNameInput, setGroupNameInput] = useState("");


  /***socket connection */
  useEffect(() => {
    const socketConnection = io(process.env.REACT_APP_BACKEND_URL, {
      auth: {
        token: localStorage.getItem('token')
      },
    })

    socketConnection.on('onlineUser', (data) => {
      console.log(data)
      dispatch(setOnlineUser(data))
    })




    dispatch(setSocketConnection(socketConnection))

    return () => {
      socketConnection.disconnect()
    }
  }, [])

  useEffect(() => {
    if (currentMessage.current) {
      currentMessage.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [groupData?.messages])

  useEffect(() => {
    const music = ringtoneRef.current;

    if (showIncomingCallModal) {
      // Play ringtone when the modal is visible
      music.loop = true; // Ensure it loops while the modal is visible
      music.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    } else {
      // Stop the ringtone when the modal is not visible
      music.pause();
      music.currentTime = 0; // Reset to the beginning
    }

    return () => {
      // Ensure the audio stops when the component unmounts
      music.pause();
      music.currentTime = 0;
    };
  }, [showIncomingCallModal]);


  // Handle starting the call with a dynamic URL or socket event
  const generateRoomId = () => Math.random().toString(36).substring(2, 15);
  // Handle starting the call with a dynamic URL or socket event for one person
  const handleStartCall = () => {
    const roomId = generateRoomId();  // Generate a unique room ID
    groupData?.members?.forEach(receiverId => {
      socketConnection.emit('start-call', {
        callerId: user._id,
        receiverId: receiverId?._id, // Send each receiver ID
        roomId // Send the same room ID to all receivers
      });
    });


    // Open the call page with the room ID as a URL parameter
    // window.open(`https://cadprouk.com/join?room=${roomId}`, "_blank");
    // setShowCallModal(false);
  };




  useEffect(() => {
    if (socketConnection) {
      // Listen for incoming calls (ensure this is only in Group.js)
      socketConnection.on('incoming-call', (callerInfo) => {


        setCallerInfo(callerInfo);
        setShowIncomingCallModal(true);

        // Set a timeout to automatically reject the call after 10 seconds
        const timeoutId = setTimeout(() => {
          handleRejectCall(); // Auto-reject the call

          if (callerInfo?.roomId) {
            const callLink = `https://cadprouk.com/join?room=${callerInfo?.roomId}`;
            toast.info(
              <div>
                Call missed, you can join here {" "}
                <a href={callLink} target="_blank" rel="noopener noreferrer" style={{ color: 'green' }}>
                  Join Now
                </a>
              </div>,
              {
                position: "top-right",
                autoClose: 600000, // 10 minutes
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }

        }, 180000); // 3 minute timeout
        setCallTimeout(timeoutId); // Save timeout ID

        // dispatch(setSocketConnection(socketConnection))


        // if (params.userId === callerInfo?.receiverId) {

        // }


      });
    }
  }, [socketConnection, params.userId, user]);

  const handleRejectCall = () => {
    setShowIncomingCallModal(false);
    if (callTimeout) {
      clearTimeout(callTimeout); // Clear the timeout
    }

    // Emit call rejection event to the caller
    socketConnection.emit('call-rejected', { callerId: callerInfo?.callerId, receiverId: user._id });

    if (callerInfo?.roomId) {
      const callLink = `https://cadprouk.com/join?room=${callerInfo?.roomId}`;
      toast.info(
        <div>
          Call missed, you can join here {" "}
          <a href={callLink} target="_blank" rel="noopener noreferrer" style={{ color: 'green' }}>
            Join Now
          </a>
        </div>,
        {
          position: "top-right",
          autoClose: 600000, // 10 minutes
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }

    // const missedCallMessage = `Call missed 📞. You can join here: ${callLink}`;

    // Send the missed call message to the chat
    // sendMissedCallMessage(missedCallMessage);

    // Show a toast notification with the call link
  };

  const handleAcceptCall = () => {
    setShowIncomingCallModal(false);
    if (callTimeout) {
      clearTimeout(callTimeout); // Clear the timeout
    }
    const roomId = callerInfo?.roomId;
    socketConnection.emit('accept-call', { roomId, callerId: callerInfo?.callerId, receiverId: user._id });
    window.open(`https://cadprouk.com/join?room=${roomId}`, "_blank");
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target
    setMessage(preve => {
      return {
        ...preve,
        text: value
      }
    })
  }

  const handleSendMessage = (e) => {
    e.preventDefault()
    sendMessage();
  }


  const checkNewMessageFromServer = (serverMessageArray) => {
    const messageArrayCount = localStorage.getItem("messageArrayCount");
    if (messageArrayCount == 'undefined' || messageArrayCount === null || messageArrayCount === '') {
      return localStorage.setItem("messageArrayCount", `${serverMessageArray?.messages?.length}`);
    }

    else if (serverMessageArray?.messages?.length > messageArrayCount && serverMessageArray?.messages[serverMessageArray?.messages?.length - 1]?.msgByUserId?._id !== senderId) {
      try {
        const audio = new Audio(notificationSound);
        audio.play().catch((err) => console.error('Audio playback failed:', err));
        localStorage.setItem("messageArrayCount", `${serverMessageArray?.messages?.length}`);
        return toast(<span><strong>New Group Message</strong>: {serverMessageArray?.messages[serverMessageArray?.messages?.length - 1]?.text}</span>);
      }

      catch (e) {
        console.log('error in show notification', e)
      }


    }
  }

  const fetchGroupDetail = async () => {
    const id = localStorage.getItem('authUserid');
    setSenderId(id);
    setLoading(true);
    try {
      const response = await axios.get(`https://crm.mailcadpro.co.uk/api/group/${params.userId}`);
      setLoading(false);
      setGroupData(response.data);
      console.log("response.data", response.data)
      setIsGroupOpen(true);
      checkNewMessageFromServer(response?.data);

    } catch (err) {
      console.log('err in fetching group', err)
      setLoading(false);

    }
  };


  const fetchGroupDetailEveryThreeSeconds = async () => {
    const id = localStorage.getItem('authUserid');
    setSenderId(id);
    // setLoading(true);
    try {
      const response = await axios.get(`https://crm.mailcadpro.co.uk/api/group/${params.userId}`);
      setGroupData(response.data);
      setIsGroupOpen(true);
      checkNewMessageFromServer(response?.data);

    } catch (err) {
      console.log('err in fetching group', err)
      setLoading(false);

    }
  };

  const handleGroupNameChange = (e) => {
    setGroupNameInput(e.target.value); // Update the state when the input value changes
  };

  const sendMissedCallMessage = (text) => {
    const dataToSubmit = {
      userID: senderId,
      text: text
    };

    axios.post(`https://crm.mailcadpro.co.uk/api/group/${params.userId}/send-message`, dataToSubmit)
      .then(response => {
        console.log('Missed call message sent:', response.data);
        fetchGroupDetail();
      })
      .catch(err => {
        console.error('Error sending missed call message:', err);
      });
  };

  const sendMessage = async () => {
    try {
      // Making the POST request
      setLoading(true);

      const dataToSubmit = {
        userID: senderId,
        text: message?.text
      }

      const response = await axios.post(`https://crm.mailcadpro.co.uk/api/group/${params.userId}/send-message`, dataToSubmit);
      console.log('Response:', response.data);
      fetchGroupDetail();
      setLoading(false);

      // groupData?.members?.forEach((userId) => {
      //   if (userId._id !== senderId) {  // Exclude the sender
      //     socketConnection.emit('private message', {
      //       message: message?.text,
      //       groupName: groupData?.groupName,
      //       recipientIds: groupData?.members.map(member => member._id),  // Send to all group members
      //     });
      //   }
      // });
      socketConnection.emit('private message', {
        message: message?.text,
        groupName: groupData?.groupName,
        // senderId :senderId,
        recipientIds: groupData?.members.map(member => member._id),  // Send to all group members
      });


      setMessage({
        text: "",
        imageUrl: "",
        videoUrl: ""
      })

    }

    catch (e) {
      console.log('error in sending messages', e);
      setLoading(false);

    }
  }


  useEffect(() => {

    fetchGroupDetail();

  }, []);

  useEffect(() => {
    let interval;
    if (isGroupOpen) {
      // Set up the interval to fetch group details every 15 seconds (15,000ms)
      interval = setInterval(() => {
        fetchGroupDetailEveryThreeSeconds();
      }, 3000); // 15,000ms = 15 seconds

      // Cleanup the interval when the component is unmounted
      return () => {
        console.log('Cleaning up interval...');
        clearInterval(interval); // Clear the interval on cleanup
      };
    }
  }, [isGroupOpen]); // Runs when `isGroupOpen` changes

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleUpdateGroup = async (updatedData) => {
    console.log("updatedData", updatedData)
    console.log("params.userId", params.userId)
    try {
      const response = await axios.put(
        `https://crm.mailcadpro.co.uk/api/group/${params.userId}`,
        updatedData
      );
      toast.success(response.data.message);
      fetchGroupDetail();
      setIsModalOpen(false);
    } catch (err) {
      console.error('Error updating group:', err);
      toast.error('Failed to update group.');
    }
  };



  return (
    <div style={{ backgroundImage: `url(${backgroundImage})` }} className='bg-no-repeat bg-cover'>
      <header className='sticky top-0 h-16 bg-white flex justify-between items-center px-4'>
        <div className='flex items-center gap-4'>
          <Link to={"/"} >
            <FaAngleLeft size={25} />
          </Link>
          <div>
            <Avatar
              width={50}
              height={50}
              imageUrl={groupData?.profile_pic}
              name={groupData?.groupName}
            // userId={dataUser?._id}
            />
          </div>
          <div>
            <h3 className='font-semibold text-lg my-0 text-ellipsis line-clamp-1'>{groupData?.groupName}</h3>
            <p className='-my-2 text-sm'>
              {
                groupData?.groupName ? <span className='text-primary'>online</span> : <span className='text-slate-400'>offline</span>
              }
            </p>
          </div>
          <button onClick={handleOpenModal} className="text-xl">
            <IoMdAdd />
          </button>
        </div>

        <div >
          <button onClick={() => handleStartCall()} className='cursor-pointer hover:text-primary'>
            <IoMdCall size={20} />
          </button>
        </div>
      </header>


      {/* Incoming Call Modal */}
      {showIncomingCallModal && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg max-w-sm w-full text-center">
            <h2 className="text-lg font-semibold mb-4">Group Call Generated</h2>
            {/* <p className="mb-6">You have an incoming call from {callerInfo?.callerName}</p> */}
            <button onClick={handleAcceptCall} className="bg-blue-500 text-white px-4 py-2 rounded mr-2">Join Call</button>
            <button onClick={handleRejectCall} className="bg-red-500 text-white px-4 py-2 rounded">Reject</button>
          </div>
        </div>
      )}



      {/***show all message */}
      <section className='h-[calc(100vh-128px)] overflow-x-hidden overflow-y-scroll scrollbar relative bg-slate-200 bg-opacity-50'>

        {/**all message show here */}
        <div className='flex flex-col gap-2 py-2 mx-2' ref={currentMessage}>
          {
            groupData && groupData?.messages?.length > 0 ? groupData?.messages?.map((val, index) => {
              return (
                <>
                  <div class="flex flex-col bg-gray-100 border border-gray-300 rounded-lg p-4 max-w-md" key={index}>
                    <span class="text-sm font-bold text-gray-700">{val?.msgByUserId?.name}</span>
                    <p class="text-gray-900 mt-2">
                      <p className='px-2'>{val?.text.startsWith('http') ? <a href={val?.text} target='_blank'>{val?.text}</a> : val?.text}</p>
                      <p className='text-xs ml-auto w-fit'>{moment(val?.createdAt).format('ddd-MM-DD hh:mm A')}</p>
                    </p>
                  </div>

                </>

              )
            }) : <p className='text-center text-lg font-bold justify-self-center mt-[10rem]'>Looks like there are no messages here yet. Be the first to start the conversation!</p>
          }
        </div>

        {
          loading && (
            <div className='w-full h-full flex sticky bottom-0 justify-center items-center'>
              <Loading />
            </div>
          )
        }
      </section>

      {/**send message */}
      <section className='h-16 bg-white flex items-center px-4'>
        {/**input box */}
        <form className='h-full w-full flex gap-2' onSubmit={handleSendMessage}>
          <input
            type='text'
            placeholder='Type here message...'
            className='py-1 px-4 outline-none w-full h-full'
            value={message.text}
            onChange={handleOnChange}
          />
          {loading ? <Loading /> : <button className='text-primary hover:text-secondary'>
            <IoMdSend size={28} />
          </button>


          }
        </form>

      </section>

      {isModalOpen && (
        <UpdateGroupModal
          onClose={handleCloseModal}
          groupNameInput={groupNameInput}
          handleGroupNameChange={handleGroupNameChange}
          onUpdateGroup={handleUpdateGroup}
          groupId={params.userId}
        />
      )}

      {/* ToastContainer to render the toast notifications */}
      <ToastContainer />
    </div>
  )
}

export default Group